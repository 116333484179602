import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { InputSearchComponent } from '@interacta-shared/ui';
import { isDefined } from '@interacta-shared/util';
import { dashboardCommunity } from '@modules/app-routing/routes';
import { ICommunity } from '@modules/communities/models/communities.model';
import { IWorkspace } from '@modules/communities/models/workspace.model';
import { AppSelectors, AppState } from '@modules/core/store';
import { filterWorkspaces } from '@modules/sidebar/models/sidebar.utils';
import { CacheMap, WorkspaceUI } from '@modules/state/models/ui-state.model';
import { UIStateService } from '@modules/state/services/ui-state.service';
import { Store } from '@ngrx/store';
import { Observable, Subject, combineLatest, concat, of } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    shareReplay,
    takeUntil,
} from 'rxjs/operators';

@Component({
    selector:
        'interacta-sidebar-advanced-community-research[workspaces][selectedCommunity]',
    templateUrl: './sidebar-advanced-community-research.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarAdvancedCommunityResearchComponent
    implements OnChanges, OnInit, OnDestroy
{
    @Input() workspaces!: Pick<IWorkspace, 'id' | 'name' | 'communities'>[];
    @Input() selectedCommunity!: ICommunity | null;
    @Input() uiStates!: CacheMap<WorkspaceUI> | null;
    @Input() collapsedWorkspaceList: number[] = [];
    @Input() expanded = false;
    @Input() showDashboardLink = false;

    @Output() collapsedWs = new EventEmitter<number>();

    @ViewChild('input') input?: InputSearchComponent;

    communityFilter = new UntypedFormControl('');

    filteredData = new Observable<
        Pick<IWorkspace, 'id' | 'name' | 'communities'>[]
    >();
    communitiesCount = 0;
    private destroy$ = new Subject<void>();

    constructor(
        public uiService: UIStateService,
        private store: Store<AppState>,
        private router: Router,
    ) {}

    navigateToCommunity(community: Pick<ICommunity, 'id'>): void {
        this.router.navigate(['/community', community.id]);
    }

    ngOnInit(): void {
        this.store
            .select(AppSelectors.selectRouteState)
            .pipe(
                filter(
                    (route) =>
                        isDefined(route) &&
                        route.appRoute !== dashboardCommunity,
                ),
                takeUntil(this.destroy$),
            )
            .subscribe((_) => this.communityFilter.setValue(''));
    }

    ngOnChanges(_changes: SimpleChanges): void {
        if (_changes.workspaces) {
            const communityFilter$ = concat(
                of(this.communityFilter.value as string),
                this.communityFilter.valueChanges.pipe(
                    debounceTime(300),
                    distinctUntilChanged(),
                    takeUntil(this.destroy$),
                    map((s) => (isDefined(s) ? (s as string) : '')),
                ),
            ).pipe(shareReplay(1));

            this.filteredData = combineLatest([
                of(this.workspaces),
                communityFilter$,
            ]).pipe(
                map(([workspaces, nameFilter]) =>
                    workspaces
                        .map((workspace) =>
                            filterWorkspaces(
                                workspace,
                                nameFilter,
                                true,
                                false,
                            ),
                        )
                        .filter(
                            (workspace) =>
                                (workspace?.communities?.length ?? 0) > 0,
                        ),
                ),
            );
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    openSidebarAndFocusInput(): void {
        this.uiService.changeLeftMenuOpen(true);

        setTimeout(() => this.input?.focus(), 300);
    }
}
