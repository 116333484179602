import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    ApplyPipe,
    ButtonComponent,
    DialogComponent,
    DialogSearchComponent,
    FormControlValueV2Pipe,
    IconButtonComponent,
    ImageComponent,
    InputSearchComponent,
    SeparatorComponent,
} from '@interacta-shared/ui';
import { IconComponent } from '@interacta-shared/ui-icons';
import { AppLogoPipe } from '@interacta-shared/util-common';
import { SharedV2Module } from '@modules/shared-v2/shared-v2.module';
import { StoreModule } from '@ngrx/store';
import { CommunitySelectionDialogComponent } from './components/community-selection-dialog/community-selection-dialog.component';
import { SidebarAdminSectionComponent } from './components/sidebar-admin-section/sidebar-admin-section.component';
import { SidebarAdminComponent } from './components/sidebar-admin/sidebar-admin.component';
import { SidebarAdvancedCommunityResearchComponent } from './components/sidebar-advanced-community-research/sidebar-advanced-community-research.component';
import { SidebarCommunityComponent } from './components/sidebar-community/sidebar-community.component';
import { SidebarSectionComponent } from './components/sidebar-section/sidebar-section.component';
import { SidebarWorkspaceListComponent } from './components/sidebar-workspace-list/sidebar-workspace-list.component';
import { SidebarWorkspaceComponent } from './components/sidebar-workspace/sidebar-workspace.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { sidebarFeatureKey, sidebarReducer } from './store/sidebar.reducer';

const standaloneComponents = [
    IconComponent,
    ButtonComponent,
    DialogComponent,
    DialogSearchComponent,
    IconButtonComponent,
    ImageComponent,
    InputSearchComponent,
    SeparatorComponent,
];

const standalonePipes = [ApplyPipe, FormControlValueV2Pipe, AppLogoPipe];

@NgModule({
    declarations: [
        SidebarComponent,
        SidebarSectionComponent,
        SidebarWorkspaceListComponent,
        SidebarWorkspaceComponent,
        SidebarCommunityComponent,
        SidebarAdvancedCommunityResearchComponent,
        CommunitySelectionDialogComponent,
        SidebarAdminComponent,
        SidebarAdminSectionComponent,
    ],
    imports: [
        CommonModule,
        SharedV2Module,
        ...standaloneComponents,
        ...standalonePipes,
        StoreModule.forFeature(sidebarFeatureKey, sidebarReducer),
    ],
    exports: [SidebarComponent, CommunitySelectionDialogComponent],
})
export class SidebarModule {}
