import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    inject,
} from '@angular/core';
import { DiscoveryService } from '@modules/discovery/services/discovery.service';
import {
    SidebarSectionAdmin,
    SidebarSectionAdminId,
} from '@modules/sidebar/models/sidebar.model';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'interacta-sidebar-admin-section',
    templateUrl: './sidebar-admin-section.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarAdminSectionComponent implements OnInit, OnChanges {
    @Input({ required: true }) section!: SidebarSectionAdmin;
    @Input() expanded = true;
    @Input() selected = false;
    @Input() selectedSectionId: SidebarSectionAdminId | null = null;

    newSection$ = new BehaviorSubject<boolean>(false);
    private discoveryService = inject(DiscoveryService);

    ngOnInit(): void {
        const newSectionIds = this.discoveryService.getNewAdminSectionIds();
        this.newSection$.next(newSectionIds.includes(this.section.id) ?? false);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selected) {
            this.selected && this.newSection$.next(false);
        }
    }

    imagePath(data: {
        section: SidebarSectionAdmin;
        selected: boolean;
        selectedSectionId: SidebarSectionAdminId | null;
    }): string {
        let imageName = data.section.imageName as string;
        const peopleSectionIds: SidebarSectionAdminId[] = [
            'people-admin',
            'user',
            'group',
            'organization',
        ];
        const isPeopleSubsectionSelected =
            data.section.id === 'people-admin' &&
            data.selectedSectionId != null &&
            peopleSectionIds.includes(data.selectedSectionId);

        if (!data.selected && !isPeopleSubsectionSelected) {
            imageName += '_BW';
        }

        return `assets/shared/images/pro-tip/${imageName}.png`;
    }
}
