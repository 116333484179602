import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IWorkspace } from '@modules/communities/models/workspace.model';

@Component({
    selector: 'interacta-sidebar-workspace',
    templateUrl: './sidebar-workspace.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarWorkspaceComponent {
    @Input({ required: true }) workspace!: Pick<
        IWorkspace,
        'id' | 'name' | 'communities'
    >;
    @Input() isCollapsed = true;
    @Input() expanded = true;
    @Input() selected = false;
}
