<a
    class="flex w-full items-center gap-x-12"
    [ngClass]="{
        grow: !expanded,
        'text-text-mid-contrast hover:text-text': !selected
    }"
    [routerLink]="[section.path]"
>
    <interacta-icon
        *ngIf="!section.imageName"
        class="mx-10 my-4 size-16 shrink-0 transition-all duration-300"
        [icon]="'square-fill'"
        [matTooltip]="section.label | translate"
        [matTooltipDisabled]="expanded"
        [ngClass]="{
            'text-text-primary': selected
        }"
    ></interacta-icon>

    <interacta-image
        *ngIf="section.imageName"
        class="shrink-0"
        [alt]="section.label"
        [classes]="'size-36'"
        [matTooltip]="section.label | translate"
        [matTooltipDisabled]="expanded"
        [src]="{ section, selected, selectedSectionId } | apply: imagePath"
    >
    </interacta-image>

    <span
        class="grow text-left transition-all duration-300"
        [ngClass]="{
            'typo-xs-bold': !section.uppercase,
            'typo-xs-bold-uppercase': section.uppercase,
            'text-text': selected,
            'opacity-0': !expanded,
            'opacity-100': expanded
        }"
    >
        {{ section.label | translate }}</span
    >

    <span
        *ngIf="section.counter"
        class="typo-xs-bold shrink-0"
        [ngClass]="{
            'text-text-primary': selected,
            'text-text': !selected,
            'opacity-0': !expanded,
            'opacity-100': expanded
        }"
    >
        {{ section.counter }}</span
    >

    <ng-container
        *ngIf="{
            newSection: newSection$ | async
        } as $"
    >
        <span
            *ngIf="$.newSection && expanded"
            class="typo-xs-bold bg-surface-primary-low-contrast ml-8 mt-2 rounded-3xl px-8 py-2"
        >
            {{ 'SIDEBAR.NEW_SECTION_BADGE' | translate }}
        </span>
    </ng-container>
</a>
