import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { openHeightAnimation } from '@interacta-shared/ui';
import { ICommunity } from '@modules/communities/models/communities.model';
import { SidebarWorkspace } from '@modules/sidebar/models/sidebar.model';
import { CacheMap, WorkspaceUI } from '@modules/state/models/ui-state.model';
import { CommunitiesStateService } from '@modules/state/services/communities-state.service';
import { StateService } from '@modules/state/services/state.service';
import { BehaviorSubject, map, Observable } from 'rxjs';

@Component({
    selector: 'interacta-sidebar-workspace-list',
    templateUrl: './sidebar-workspace-list.component.html',
    styles: [],
    animations: [openHeightAnimation('pinned', '*')],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarWorkspaceListComponent implements OnChanges {
    @Input({ required: true }) workspaceList!: SidebarWorkspace[];
    @Input({ required: true }) selectedCommunity!: ICommunity | null;
    @Input({ required: true }) uiStates!: CacheMap<WorkspaceUI> | null;
    @Input() expanded = true;
    @Input() indentation = true;
    @Input() redirectTo: 'community' | 'create' = 'community';
    @Input() showDashboardLink = false;
    @Input() pinEnabled = false;

    @Output() collapsedWs = new EventEmitter<number>();
    @Output() selectedCommunityChanged = new EventEmitter<ICommunity>();

    showMyCommunities$ = new BehaviorSubject(true);
    pinnedCommunities$?: Observable<ICommunity[]>;

    constructor(
        public appState: StateService,
        private communitiesStateService: CommunitiesStateService,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.workspaceList) {
            if (this.workspaceList && this.workspaceList.length) {
                if (this.workspaceList.length === 1) {
                    const commmunitiesVisibleInDashboard =
                        this.workspaceList[0].communities?.filter(
                            (c) => c.visibleInDashboard,
                        );

                    this.showMyCommunities$.next(
                        !commmunitiesVisibleInDashboard?.length ||
                            commmunitiesVisibleInDashboard?.length === 1
                            ? false
                            : true,
                    );
                } else {
                    this.showMyCommunities$.next(true);
                }
            } else {
                this.showMyCommunities$.next(false);
            }

            this.pinnedCommunities$ =
                this.appState.communitiesState.pinnedCommunitiesIds$.pipe(
                    map((pinnedIds) => {
                        const allCommunities = (
                            this.workspaceList ?? []
                        ).flatMap((workspace) => workspace.communities ?? []);

                        return allCommunities
                            .map((community) => ({
                                community,
                                pinnedId: pinnedIds.find(
                                    (p) => p.id === community.id,
                                ),
                            }))
                            .filter(({ pinnedId }) => pinnedId != null)
                            .map(({ community, pinnedId }) => ({
                                ...community,
                                pinnedTimestamp: pinnedId?.pinnedTimestamp,
                            }))
                            .sort(
                                (a, b) =>
                                    (a.pinnedTimestamp ?? 0) -
                                    (b.pinnedTimestamp ?? 0),
                            );
                    }),
                );
        }
    }

    pinCommunity(data: { community: ICommunity; value: boolean }): void {
        this.communitiesStateService.pinCommunity(
            data.community.id,
            data.value,
        );
    }

    isPinned(data: {
        community: ICommunity;
        pinnedCommunities: ICommunity[];
    }): boolean {
        return data.pinnedCommunities.some((c) => c.id === data.community.id);
    }
}
