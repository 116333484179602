<interacta-sidebar-section
    class="w-full"
    [expanded]="expanded"
    [id]="'adminV2Sidebar'"
    [section]="section"
    [selected]="true"
    (toggleAdvancedSidebar)="toggleSection.emit()"
/>
<div
    *ngIf="{ sectionSelected: sectionSelected$ | async } as $"
    class="flex flex-col gap-y-8 overflow-x-hidden px-16"
>
    <interacta-sidebar-admin-section
        *ngFor="let item of visibleSectionAdminItems()"
        class="grow"
        [expanded]="expanded"
        [section]="item"
        [selected]="item.id === $.sectionSelected"
        [selectedSectionId]="$.sectionSelected"
    />
</div>
