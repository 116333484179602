<div
    *ngIf="{
        uiStates: workspacesUI$ | async,
        sectionSelected: sectionSelected$ | async,
        operativityCommunities:
            appState.communitiesState.operativityCommunities$ | async,
        selectedCommunity: selectedCommunity$ | async,
        sidebarWorkspaces: sidebarWorkspaces$ | async,
        advancedCommunitySidebar: (advancedCommunitySidebar$ | async) ?? false,
        showAdvancedCommunityResearch:
            (showAdvancedCommunityResearch$ | async) ?? false,
        showAdminV2Sidebar: showAdminV2Sidebar$ | async,
        showDashboardLink: (showDashboardLink$ | async) ?? false,
        userCapabilities: userCapabilities$ | async,
        aiEnabled: aiEnabled$ | async
    } as $"
    class="flex size-full flex-col overflow-hidden"
    id="sidebar"
>
    <button
        *ngIf="
            $.sectionSelected &&
            createButtonSections.includes($.sectionSelected.sectionId) &&
            $.userCapabilities
        "
        class="my-4 ml-12 w-max shrink-0"
        interacta-button
        type="button"
        [disabled]="
            {
                sectionSelected: $.sectionSelected,
                operativityCommunitiesLength: $.operativityCommunities?.length,
                selectedCommunity: $.selectedCommunity,
                userCapabilities: $.userCapabilities
            } | apply: disableCreateNewButton
        "
        [icon]="'plus'"
        [label]="
            expanded
                ? ($.sectionSelected | apply: createNewButtonLabel | translate)
                : ''
        "
        [size]="'regular'"
        (click)="handleCreateClick($.sectionSelected, $.selectedCommunity)"
    ></button>

    <!-- Sezione NON scrollabile, Home e Community -->
    <div
        *ngFor="let sidebarSection of sidebarNotScrollableSections"
        class="duration-250 transition-transform"
        [attr.inert]="
            $.showAdvancedCommunityResearch || $.showAdminV2Sidebar ? '' : null
        "
        [style.transform]="
            'translateX(' +
            ($.showAdvancedCommunityResearch || $.showAdminV2Sidebar
                ? '-100%'
                : '0') +
            ')'
        "
    >
        <interacta-sidebar-section
            class="my-8"
            tabindex="-1"
            [advancedSidebar]="
                $.advancedCommunitySidebar &&
                sidebarSection.id === 'community' &&
                !$.showAdvancedCommunityResearch
            "
            [disabled]="
                sidebarSection.id === 'community' && !$.advancedCommunitySidebar
            "
            [expanded]="expanded"
            [id]="
                $.advancedCommunitySidebar && sidebarSection.id === 'community'
                    ? 'community'
                    : ''
            "
            [section]="sidebarSection"
            [selected]="$.sectionSelected?.sectionId === sidebarSection.id"
            (toggleAdvancedSidebar)="
                $.advancedCommunitySidebar &&
                    toggleAdvancedSidebar(
                        sidebarSection.id,
                        $.showAdvancedCommunityResearch
                    )
            "
        ></interacta-sidebar-section>
    </div>

    <!-- contenitore della sezione scrollabile -->
    <div
        class="relative h-full grow overflow-y-auto"
        [attr.inert]="
            $.showAdvancedCommunityResearch || $.showAdminV2Sidebar ? '' : null
        "
    >
        <div
            class="duration-250 flex h-full flex-col transition-transform"
            [style.transform]="
                'translateX(' +
                ($.showAdvancedCommunityResearch || $.showAdminV2Sidebar
                    ? '-100%'
                    : '0') +
                ')'
            "
        >
            <interacta-invisible-scroll class="min-h-0 grow">
                <interacta-sidebar-workspace-list
                    *ngIf="!$.advancedCommunitySidebar && $.sidebarWorkspaces"
                    [expanded]="expanded"
                    [pinEnabled]="true"
                    [selectedCommunity]="$.selectedCommunity"
                    [showDashboardLink]="$.showDashboardLink"
                    [uiStates]="$.uiStates ?? null"
                    [workspaceList]="$.sidebarWorkspaces"
                    (collapsedWs)="onCollapsedWs($event)"
                    (selectedCommunityChanged)="navigateToCommunity($event)"
                />

                <div class="flex flex-col overflow-x-hidden">
                    <interacta-sidebar-section
                        *ngFor="
                            let sidebarSection of otherVisibleSidebarSections()
                        "
                        [advancedSidebar]="
                            ($.advancedCommunitySidebar &&
                                sidebarSection.id === 'community') ||
                            sidebarSection.id === 'admin-v2'
                        "
                        [expanded]="expanded"
                        [id]="sidebarSection.id"
                        [section]="sidebarSection"
                        [selected]="
                            $.sectionSelected?.sectionId === sidebarSection.id
                        "
                        (toggleAdvancedSidebar)="
                            toggleAdvancedSidebar(
                                sidebarSection.id,
                                sidebarSection.id === 'community'
                                    ? $.showAdvancedCommunityResearch
                                    : $.showAdminV2Sidebar
                            )
                        "
                    />
                    @if ($.aiEnabled) {
                        <button
                            class="bg-surface-B transition-width text-text-mid-contrast hover:text-text relative my-16 ml-8 flex max-h-56 items-center gap-x-8 rounded-xl p-12"
                            type="button"
                            [ngClass]="{
                                'w-48': !expanded,
                                'w-224': expanded
                            }"
                            (click)="openAIPanel()"
                        >
                            <interacta-icon
                                class="size-24 shrink-0"
                                [icon]="'book-reader'"
                            ></interacta-icon>

                            <p
                                class="typo-xs max-h-32 overflow-hidden text-left transition-opacity duration-300"
                                [innerHTML]="
                                    'AI.SIDEBAR_GUIDE_CENTER' | translate
                                "
                                [ngClass]="{
                                    'w-0 opacity-0': !expanded,
                                    'w-full opacity-100': expanded
                                }"
                            ></p>

                            <interacta-image
                                alt="ai logo"
                                class="absolute -right-6 -top-6"
                                [classes]="'size-20'"
                                [loading]="'eager'"
                                [src]="
                                    currentTheme.mode === 'dark'
                                        ? './assets/images-2.0/ai/ai-interacta-logo_dark.svg'
                                        : './assets/images-2.0/ai/ai-interacta-logo_light.svg'
                                "
                            ></interacta-image>
                        </button>
                    }
                </div>
            </interacta-invisible-scroll>
        </div>
    </div>

    @if (!$.showAdvancedCommunityResearch && !$.showAdminV2Sidebar) {
        <img
            *ngIf="!enableChristmasEasterEgg"
            alt="logo"
            class="my-20 ml-10 w-44 shrink-0"
            [src]="currentTheme.mode | appLogo"
        />
        <button
            *ngIf="enableChristmasEasterEgg"
            class="my-20 ml-20 w-44 shrink-0"
            type="button"
            (click)="christmasDialogOpen = true"
        >
            <img
                alt="christmas gift image"
                [src]="
                    currentTheme.mode === 'dark'
                        ? './assets/images-2.0/gift_dark.svg'
                        : './assets/images-2.0/gift.svg'
                "
            />
        </button>
    }

    <!-- TITOLO NON SCROLLABILE DEL SUBMENU COMMUNITY -->
    <div
        *ngIf="$.showAdvancedCommunityResearch && $.sidebarWorkspaces"
        class="top-116 w-252 duration-250 absolute flex grow flex-col transition-transform"
        [style.transform]="
            'translateX(' +
            ($.showAdvancedCommunityResearch ? '0' : '100%') +
            ')'
        "
        (transitionend)="isAnimatingAdvancedTab = false"
        (transitionstart)="isAnimatingAdvancedTab = true"
    >
        <interacta-sidebar-section
            [expanded]="expanded"
            [id]="'advancedCommunityResearch'"
            [section]="communityAdvancedSection"
            [selected]="true"
            (toggleAdvancedSidebar)="
                toggleAdvancedCommunityResearchAndSwitchFocus(
                    $.showAdvancedCommunityResearch
                )
            "
        />
    </div>

    <!-- SUBMENU DELLE COMMUNITY -->
    <div
        class="top-168 w-252 duration-250 absolute flex grow flex-col overflow-y-auto transition-transform"
        [attr.inert]="
            !$.showAdvancedCommunityResearch && !$.showAdminV2Sidebar
                ? ''
                : null
        "
        [ngClass]="{ 'h-[calc(100%-11rem)]': $.showAdvancedCommunityResearch }"
        [style.transform]="
            'translateX(' +
            ($.showAdvancedCommunityResearch ? '0' : '100%') +
            ')'
        "
        (transitionend)="isAnimatingAdvancedTab = false"
        (transitionstart)="isAnimatingAdvancedTab = true"
    >
        <interacta-invisible-scroll>
            <interacta-sidebar-advanced-community-research
                *ngIf="$.sidebarWorkspaces"
                class="bg-surface-A"
                [expanded]="expanded"
                [hidden]="
                    !$.showAdvancedCommunityResearch && !isAnimatingAdvancedTab
                "
                [selectedCommunity]="$.selectedCommunity"
                [showDashboardLink]="$.showDashboardLink"
                [uiStates]="$.uiStates ?? null"
                [workspaces]="$.sidebarWorkspaces"
                (collapsedWs)="onCollapsedWs($event)"
            />
        </interacta-invisible-scroll>
    </div>

    <!-- SUBMENU Amministrazione v2 -->
    <div
        class="top-116 w-252 duration-250 absolute grow transition-transform"
        [attr.inert]="
            !$.showAdvancedCommunityResearch && !$.showAdminV2Sidebar
                ? ''
                : null
        "
        [style.transform]="
            'translateX(' + ($.showAdminV2Sidebar ? '0' : '100%') + ')'
        "
        (transitionend)="isAnimatingAdminV2Tab = false"
        (transitionstart)="isAnimatingAdminV2Tab = true"
    >
        <interacta-sidebar-admin
            [expanded]="expanded"
            [hidden]="!$.showAdminV2Sidebar && !isAnimatingAdminV2Tab"
            [section]="adminV2Section"
            (toggleSection)="
                toggleAdvancedAdminSidebarAndSwitchFocus($.showAdminV2Sidebar)
            "
        />
    </div>
</div>
<interacta-community-selection-dialog
    *ngIf="{
        showCommunitySelectionDialog:
            sidebarService.communitySelectionForCreateDialogSettings$ | async,
        operativityWorkspaces: operativityWorkspaces$ | async,
        recentCommunities: (recentCommunities$ | async) ?? undefined
    } as $"
    [isOpen]="$.showCommunitySelectionDialog?.isOpen ?? false"
    [recentCommunities]="$.recentCommunities"
    [workspaces]="$.operativityWorkspaces"
    (closeDialog)="$.showCommunitySelectionDialog?.callback(null)"
    (selectedCommunity)="$.showCommunitySelectionDialog?.callback($event)"
/>

<interacta-dialog
    *ngIf="enableChristmasEasterEgg && christmasDialogOpen"
    [isOpen]="christmasDialogOpen"
    [size]="'regular'"
    (close)="christmasDialogOpen = false"
    (closing)="christmasDialogOpen = false"
>
    <div title></div>
    <video
        autoplay
        class="h-screen w-full overflow-hidden pb-32"
        content
        muted
        [currentTime]="0"
        [src]="
            'https://storage.googleapis.com/interacta-registry-main-storage-prod/shared/assets/easter-eggs/happy_holidays.mp4'
        "
        (click)="$event.stopImmediatePropagation()"
        (ended)="christmasDialogOpen = false"
        (error)="christmasDialogOpen = false"
    >
        <p>{{ 'HAPPY_HOLIDAYS' | translate }}</p>
    </video>
</interacta-dialog>
