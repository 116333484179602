<div
    class="ml-8 flex h-full shrink-0 items-center py-4"
    [ngClass]="{
        'text-text-primary': selected,
        'text-text-mid-contrast hover:text-text': !selected
    }"
>
    <interacta-icon
        class="mr-8 size-24"
        icon="workspace"
        [matTooltip]="workspace.name | currentLanguage"
        [matTooltipDisabled]="expanded"
    ></interacta-icon>
    <div class="flex grow items-center">
        <span
            class="typo-xs-bold-uppercase line-clamp-2 grow break-words transition-opacity duration-500"
            [ngClass]="{ 'opacity-0': !expanded, 'opacity-100': expanded }"
            >{{ workspace.name }}</span
        >
        <interacta-icon
            class="size-24"
            [icon]="isCollapsed ? 'chevron-small-up' : 'chevron-small-down'"
        ></interacta-icon>
    </div>
</div>
