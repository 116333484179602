import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { ICommunity } from '@modules/communities/models/communities.model';

@Component({
    selector: 'interacta-sidebar-community',
    templateUrl: './sidebar-community.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarCommunityComponent {
    @Input({ required: true }) community!: Pick<ICommunity, 'id' | 'name'>;
    @Input() selected = false;
    @Input() expanded = true;
    @Input() pinned = false;
    @Input() pinVisible = false;
    @Output() communitySelected = new EventEmitter<void>();
    @Output() communityPinned = new EventEmitter<boolean>();
}
