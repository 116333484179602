import { inject, Pipe, PipeTransform } from '@angular/core';
import { ThemeMode } from '../models/theme-mode.model';
import { APP_LOGO } from '../providers/app-logo.provider';

@Pipe({
    name: 'appLogo',
    standalone: true,
})
export class AppLogoPipe implements PipeTransform {
    private appLogo = inject(APP_LOGO);

    transform(value: ThemeMode | null | undefined): string {
        return value ? this.appLogo[value] : this.appLogo.light;
    }
}
