import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    inject,
} from '@angular/core';
import { DiscoveryService } from '@modules/discovery/services/discovery.service';
import { SidebarSection } from '@modules/sidebar/models/sidebar.model';
import { ReplaySubject } from 'rxjs';

@Component({
    selector: 'interacta-sidebar-section',
    templateUrl: './sidebar-section.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarSectionComponent implements OnChanges, OnInit {
    @Input({ required: true }) section!: SidebarSection;
    @Input() selected = false;
    @Input() advancedSidebar = false;
    @Input() expanded = true;
    @Input() disabled = false;
    @Input() id?: string;

    @Output() toggleAdvancedSidebar = new EventEmitter<void>();

    newSection$ = new ReplaySubject<boolean>(1);

    private discoveryService = inject(DiscoveryService);

    ngOnInit(): void {
        const newSectionIds = this.discoveryService.getNewSectionIds();
        this.newSection$.next(newSectionIds.includes(this.section.id) ?? false);
    }

    ngOnChanges(): void {
        if (this.selected === true) {
            this.newSection$.next(false);
        }
    }

    selectSection(section: SidebarSection): void {
        if (section.hidden) return;

        this.toggleAdvancedSidebar.emit();
    }
}
