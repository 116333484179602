<div class="mx-12 my-8 flex items-center">
    <a
        *ngIf="section.path != null"
        class="hover:bg-surface-100 rounded-4 hover:text-text flex size-full items-center overflow-hidden py-8"
        [ngClass]="{ 'text-text-mid-contrast ': !selected }"
        [routerLink]="[section.path]"
    >
        <ng-container *ngTemplateOutlet="buttonSection"></ng-container>
    </a>

    <!-- button used when path is null or when section has an advanced sidebar-->
    <ng-container *ngIf="section.path == null">
        <button
            *ngIf="!disabled; else disabledSection"
            class="hover:bg-surface-100 rounded-4 hover:text-text flex size-full items-center justify-start overflow-hidden py-8"
            type="button"
            [id]="id"
            [ngClass]="{ 'text-text-mid-contrast ': !selected }"
            (click)="selectSection(section)"
        >
            <ng-container *ngTemplateOutlet="sectionContent"></ng-container>
        </button>
        <ng-template #disabledSection>
            <div class="flex w-max items-center">
                <ng-container *ngTemplateOutlet="sectionContent"></ng-container>
            </div>
        </ng-template>
        <ng-template #sectionContent>
            <ng-container *ngTemplateOutlet="buttonSection"></ng-container>
            <interacta-icon
                *ngIf="expanded && advancedSidebar"
                class="size-24 shrink-0 transition-opacity duration-300"
                [icon]="'chevron-small-right'"
                [ngClass]="{
                    'opacity-0': !expanded,
                    'opacity-100': expanded,
                    'text-text-primary': selected
                }"
            ></interacta-icon>
        </ng-template>
    </ng-container>
</div>

<ng-template #buttonSection>
    <interacta-icon
        *ngIf="section.icon"
        class="hover:bg-surface-100 rounded-4 size-24 shrink-0 overflow-hidden transition-colors duration-300"
        [icon]="section.icon"
        [matTooltip]="section.label | translate"
        [matTooltipDisabled]="expanded"
        [ngClass]="{
            'text-text-primary': selected,
            'order-first': section.iconPosition === 'left',
            'mx-8': section.id !== 'admin-v2',
            'mx-10': section.id === 'admin-v2'
        }"
    ></interacta-icon>

    <span
        class="typo-medium-bold line-clamp-1 overflow-hidden text-left transition-all duration-300"
        [ngClass]="{
            'text-text': selected,
            'opacity-0': !expanded,
            'opacity-100': expanded
        }"
    >
        {{ section.label | translate }}</span
    >
    <ng-container
        *ngIf="{
            newSection: newSection$ | async
        } as $"
    >
        <span
            *ngIf="$.newSection"
            class="typo-xs-bold bg-surface-primary-low-contrast ml-8 mt-2 rounded-3xl px-8 py-2"
            >{{ 'SIDEBAR.NEW_SECTION_BADGE' | translate }}</span
        >
    </ng-container>
</ng-template>
