<interacta-dialog
    *ngIf="{
        filteredWorkspaces: filteredWorkspaces$ | async,
        communitiesTotalCount: (communitiesTotalCount$ | async) ?? 0
    } as $"
    [isOpen]="isOpen"
    (closing)="closeDialog.emit()"
>
    <h6 class="typo-t6 shrink-0" title>
        {{ 'SIDEBAR.SELECT_COMMUNITY' | translate }}
    </h6>

    <interacta-dialog-search
        *ngIf="$.communitiesTotalCount > 20"
        search
        [control]="communityFilterDialog"
        [iconPosition]="'right'"
        [label]="'SHARED.BUTTON.SEARCH' | translate"
    />

    <div class="mt-24 flex min-h-0 grow flex-col" content>
        <interacta-recent-communities
            *ngIf="
                recentCommunities &&
                recentCommunities.length &&
                $.communitiesTotalCount > 1
            "
            [recentCommunities]="recentCommunities"
            (selectedCommunity)="selectedCommunity.next($event)"
        />
        <div
            *ngIf="$.filteredWorkspaces && $.filteredWorkspaces.length"
            class="flex min-h-0 grow flex-col"
        >
            <span
                *ngIf="
                    recentCommunities &&
                    recentCommunities.length &&
                    $.communitiesTotalCount > 1
                "
                class="typo-sm-bold text-text mb-4 shrink-0"
                >{{ 'SIDEBAR.HOME' | translate }}</span
            >
            <interacta-sidebar-workspace-list
                class="min-h-0 grow overflow-y-auto"
                [indentation]="false"
                [selectedCommunity]="null"
                [uiStates]="dialogUiStates"
                [workspaceList]="$.filteredWorkspaces"
                (collapsedWs)="onCollapsedWs($event)"
                (selectedCommunityChanged)="selectedCommunity.next($event)"
            />
        </div>
    </div>
</interacta-dialog>
