import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { isDefined } from '@interacta-shared/util';
import { ICommunity } from '@modules/communities/models/communities.model';
import { SidebarWorkspace } from '@modules/sidebar/models/sidebar.model';
import { filterWorkspaces } from '@modules/sidebar/models/sidebar.utils';
import { CacheMap, WorkspaceUI } from '@modules/state/models/ui-state.model';
import {
    Observable,
    ReplaySubject,
    Subject,
    combineLatest,
    concat,
    of,
} from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    map,
    shareReplay,
    takeUntil,
} from 'rxjs/operators';

@Component({
    selector: 'interacta-community-selection-dialog',
    templateUrl: './community-selection-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommunitySelectionDialogComponent
    implements OnInit, OnChanges, OnDestroy
{
    @Input({ required: true }) workspaces!: SidebarWorkspace[] | null;
    @Input() isOpen = false;
    @Input() recentCommunities?: ICommunity[];

    @Output() closeDialog = new EventEmitter<void>();
    @Output() selectedCommunity = new EventEmitter<ICommunity>();

    communityFilterDialog = new UntypedFormControl('');

    filteredWorkspaces$ = new Observable<SidebarWorkspace[]>();
    dialogUiStates: CacheMap<WorkspaceUI> = {};
    communitiesTotalCount$: Observable<number>;

    private workspaces$ = new ReplaySubject<SidebarWorkspace[]>(1);
    private destroy$ = new Subject<void>();

    constructor() {
        this.communitiesTotalCount$ = this.workspaces$.pipe(
            map((workspaces) =>
                workspaces
                    .map((w) => w.communities?.length ?? 0)
                    .reduce((a, b) => a + b, 0),
            ),
        );
    }

    onCollapsedWs(id: number): void {
        this.dialogUiStates[id] = {
            isAccordionOpen: !this.dialogUiStates[id].isAccordionOpen,
        };
    }

    ngOnInit(): void {
        this.workspaces$
            .pipe(takeUntil(this.destroy$))
            .subscribe((workspaces) =>
                workspaces.forEach((w) => {
                    this.dialogUiStates[w.id] = {
                        isAccordionOpen: true,
                    };
                }),
            );

        const communityFilterDialog$ = concat(
            of(''),
            this.communityFilterDialog.valueChanges.pipe(
                debounceTime(300),
                distinctUntilChanged(),
                takeUntil(this.destroy$),
                map((s) => (isDefined(s) ? (s as string) : '')),
            ),
        ).pipe(shareReplay(1));

        this.filteredWorkspaces$ = combineLatest([
            this.workspaces$,
            communityFilterDialog$,
        ]).pipe(
            map(([workspaces, nameFilter]) =>
                workspaces
                    .map((workspace) =>
                        filterWorkspaces(workspace, nameFilter, true, false),
                    )
                    .filter(
                        (workspace) => (workspace?.communities ?? []).length,
                    ),
            ),
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.workspaces) {
            this.workspaces$.next(this.workspaces ?? []);
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }
}
