<div class="flex flex-col">
    <interacta-input-search
        *ngIf="expanded"
        #input
        class="ml-12 mr-4"
        iconPosition="right"
        [control]="communityFilter"
        [label]="'SHARED.BUTTON.SEARCH' | translate"
    ></interacta-input-search>

    <button
        *ngIf="!expanded"
        class="text-text-mid-contrast ml-12 mr-4 h-40 w-max pl-4"
        icon="search-left"
        interacta-icon-button
        type="button"
        [attr.aria-label]="'SIDEBAR.TOGGLE' | translate"
        (click)="openSidebarAndFocusInput()"
    ></button>
    <div
        *ngIf="{
            workspaces: filteredData | async,
            filter: communityFilter | formControlValueV2$ | async
        } as $"
        class="flex grow flex-col overflow-y-auto overflow-x-hidden pb-2"
    >
        <interacta-sidebar-workspace-list
            *ngIf="$.workspaces"
            [expanded]="expanded"
            [pinEnabled]="true"
            [selectedCommunity]="selectedCommunity"
            [showDashboardLink]="showDashboardLink && !$.filter"
            [uiStates]="uiStates"
            [workspaceList]="$.workspaces"
            (collapsedWs)="collapsedWs.emit($event)"
            (selectedCommunityChanged)="navigateToCommunity($event)"
        ></interacta-sidebar-workspace-list>
    </div>
</div>
