import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    computed,
    inject,
    signal,
} from '@angular/core';
import { isDefined } from '@interacta-shared/util';
import { UserCapabilitiesService } from '@modules/core/services/user-capabilites.service';
import { AppSelectors, AppState } from '@modules/core/store';
import {
    SidebarSection,
    SidebarSectionAdminId,
} from '@modules/sidebar/models/sidebar.model';
import {
    filterHiddenSections,
    routeToSidebarSectionAdmin,
    sidebarSectionAdminItems,
} from '@modules/sidebar/models/sidebar.utils';
import { Store } from '@ngrx/store';
import { Observable, Subject, filter, map, takeUntil } from 'rxjs';

@Component({
    selector: 'interacta-sidebar-admin',
    templateUrl: './sidebar-admin.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarAdminComponent implements OnInit, OnDestroy {
    @Input({ required: true }) section!: SidebarSection;
    @Input() expanded = true;

    @Output() toggleSection = new EventEmitter<void>();

    sectionSelected$!: Observable<SidebarSectionAdminId | null>;

    private sidebarSectionAdminItems = signal(sidebarSectionAdminItems);
    visibleSectionAdminItems = computed(() =>
        filterHiddenSections(this.sidebarSectionAdminItems()),
    );

    private readonly store = inject(Store<AppState>);
    private readonly userCapabilitesService = inject(UserCapabilitiesService);
    private readonly destroy$ = new Subject<void>();

    ngOnInit(): void {
        this.userCapabilitesService.userCapabilities$
            .pipe(takeUntil(this.destroy$))
            .subscribe((userCapabilities) =>
                this.sidebarSectionAdminItems.update((items) =>
                    items.map((section) => {
                        switch (section.id) {
                            case 'people-admin':
                            case 'user':
                            case 'group':
                            case 'organization':
                                return {
                                    ...section,
                                    hidden: !userCapabilities.manageUsers,
                                };
                            case 'roles':
                                return {
                                    ...section,
                                    hidden: !userCapabilities.manageRoles,
                                };
                            case 'workspace':
                                return {
                                    ...section,
                                    hidden: !userCapabilities.manageWorkspaces,
                                };
                            case 'community':
                                return {
                                    ...section,
                                    hidden: !userCapabilities.manageCommunities,
                                };
                            case 'workflow_template':
                                return {
                                    ...section,
                                    hidden: !userCapabilities.manageWorkflowTemplates,
                                };
                            case 'catalog':
                                return {
                                    ...section,
                                    hidden: !userCapabilities.manageCatalogs,
                                };
                            default:
                                return section;
                        }
                    }),
                ),
            );

        this.sectionSelected$ = this.store
            .select(AppSelectors.selectRouteState)
            .pipe(
                filter(isDefined),
                map((route) => routeToSidebarSectionAdmin(route)),
            );
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }
}
