import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnDestroy,
    OnInit,
    computed,
    signal,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService, CurrentUser } from '@interacta-shared/data-access-auth';
import { ConfigurationService } from '@interacta-shared/data-access-configuration';
import { filterMap, isDefined } from '@interacta-shared/util';
import { openCreationDialog } from '@modules/admin-v2-shared/store/shared.actions';
import { AdminV2SharedState } from '@modules/admin-v2-shared/store/shared.reducer';
import { AIActions } from '@modules/ai/store/ai.actions';
import {
    adminV2WorkflowCreate,
    dashboardCommunity,
    postCreate,
} from '@modules/app-routing/routes';
import { ICommunity } from '@modules/communities/models/communities.model';
import { CommunityActions } from '@modules/communities/store';
import {
    selectAdminV2TabOpened,
    selectAdvancedCommunityResearchOpened,
    selectRecentCommunities,
} from '@modules/communities/store/community/community.selectors';
import { UserCapabilities } from '@modules/core';
import { Theme } from '@modules/core/models/theme.model';
import { UserCapabilitiesService } from '@modules/core/services/user-capabilites.service';
import { AppSelectors, AppState } from '@modules/core/store';
import {
    CreateButtonDisabledAdminSections,
    CreateButtonDisabledSections,
    CreateButtonSections,
    SectionSelected,
    SidebarSection,
    SidebarSectionId,
    SidebarWorkspace,
} from '@modules/sidebar/models/sidebar.model';
import {
    AdminV2Section,
    CommunityAdvancedSection,
    createNewButtonLabel,
    filterHiddenSections,
    filterWorkspaces,
    getSelectedSidebarSection,
    routeToSidebarSectionAdmin,
    sidebarNotScrollableSections,
    sidebarScrollableSectionItems,
} from '@modules/sidebar/models/sidebar.utils';
import { SidebarService } from '@modules/sidebar/services/sidebar.service';
import { selectShowDashboardLink } from '@modules/sidebar/store/sidebar.selectors';
import { CacheMap, WorkspaceUI } from '@modules/state/models/ui-state.model';
import { CommunitiesStateService } from '@modules/state/services/communities-state.service';
import { StateService } from '@modules/state/services/state.service';
import { UIStateService } from '@modules/state/services/ui-state.service';
import { Store } from '@ngrx/store';
import { Observable, Subject, combineLatest, concat, of } from 'rxjs';
import {
    concatMap,
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    shareReplay,
    takeUntil,
    withLatestFrom,
} from 'rxjs/operators';

const COMMUNITY_NUMBER_LIMIT = 10;

@Component({
    selector: 'interacta-sidebar',
    templateUrl: './sidebar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit, OnDestroy {
    @Input({ required: true }) currentTheme!: Theme;
    @Input() expanded = false;
    @Input() enableChristmasEasterEgg = false;

    recentCommunities$!: Observable<ICommunity[]>;
    sectionSelected$!: Observable<SectionSelected>;
    selectedCommunity$!: Observable<ICommunity | null>;
    /**
     * advancedCommunitySidebar = true means that communties are displayed in submenu
     * advancedCommunitySidebar = false means that communties are displayed under
     *      community menu item and there isn't a community submenu
     */
    advancedCommunitySidebar$!: Observable<boolean>;
    showAdvancedCommunityResearch$!: Observable<boolean>;
    showAdminV2Sidebar$!: Observable<boolean>;
    isAnimatingAdvancedTab = false;
    isAnimatingAdminV2Tab = false;
    communityFilter = new UntypedFormControl('');
    filteredData = new Observable<SidebarWorkspace[]>();
    operativityWorkspaces$ = new Observable<SidebarWorkspace[]>();
    sidebarWorkspaces$!: Observable<SidebarWorkspace[]>;
    showDashboardLink$!: Observable<boolean>;
    christmasDialogOpen = false;
    private currentUser: CurrentUser | null;
    userCapabilities$: Observable<UserCapabilities>;
    aiEnabled$: Observable<boolean>;

    readonly communityAdvancedSection = CommunityAdvancedSection;
    readonly adminV2Section = AdminV2Section;
    readonly sidebarNotScrollableSections = sidebarNotScrollableSections;

    otherSidebarSections = signal<SidebarSection[]>(
        sidebarScrollableSectionItems,
    );

    otherVisibleSidebarSections = computed(() =>
        filterHiddenSections(this.otherSidebarSections()),
    );

    workspacesUI$!: Observable<CacheMap<WorkspaceUI> | undefined>;

    readonly createNewButtonLabel = createNewButtonLabel;
    readonly createButtonSections = CreateButtonSections;

    private workspaces$!: Observable<SidebarWorkspace[]>;
    private destroy$ = new Subject<void>();

    constructor(
        public workspacesUiService: UIStateService,
        public sidebarService: SidebarService,
        public appState: StateService,
        private router: Router,
        private adminV2SharedStore: Store<AdminV2SharedState>,
        private store: Store<AppState>,
        private communitiesStateService: CommunitiesStateService,
        private configurationService: ConfigurationService,
        userCapabilitiesService: UserCapabilitiesService,
        authService: AuthService,
    ) {
        this.currentUser = authService.getCurrentUserData();
        this.workspacesUI$ = appState.uiState.workspacesUI.onDataChange();
        this.userCapabilities$ = userCapabilitiesService.userCapabilities$;
        this.aiEnabled$ = this.configurationService
            .getEnvironmentInfoStream()
            .pipe(map((env) => env?.installedFeatures.aiGuideCenter ?? false));
    }

    ngOnInit(): void {
        const communityTree$ = this.appState.communitiesState.communityTree$;

        const communityFilter$ = concat(
            of(''),
            this.communityFilter.valueChanges.pipe(
                debounceTime(300),
                distinctUntilChanged(),
                takeUntil(this.destroy$),
                map((s) => (isDefined(s) ? (s as string) : '')),
            ),
        ).pipe(shareReplay(1));

        this.workspaces$ = communityTree$.pipe(
            map((communityTree) => communityTree?.workspaceList ?? []),
            shareReplay(1),
        );

        this.filteredData = combineLatest([
            this.workspaces$,
            communityFilter$,
        ]).pipe(
            map(([workspaces, nameFilter]) =>
                workspaces
                    .map((workspace) =>
                        filterWorkspaces(workspace, nameFilter, true, false),
                    )
                    .filter(
                        (workspace) => (workspace?.communities ?? []).length,
                    ),
            ),
        );

        this.operativityWorkspaces$ = this.workspaces$.pipe(
            map((workspaces) =>
                workspaces
                    .map((workspace) =>
                        filterWorkspaces(workspace, '', true, true),
                    )
                    .filter((workspace) => workspace.communities?.length),
            ),
        );

        this.sidebarWorkspaces$ = this.workspaces$.pipe(
            map((workspaces) =>
                workspaces.filter(
                    (workspace) => workspace.visibleInOrganizationTree,
                ),
            ),
        );

        this.advancedCommunitySidebar$ = communityTree$.pipe(
            map((communityTree) =>
                communityTree?.communityList.filter(
                    (c) => c.visibleInOrganizationTree,
                ),
            ),
            map(
                (communities) =>
                    (communities?.length ?? 0) > COMMUNITY_NUMBER_LIMIT,
            ),
        );

        this.selectedCommunity$ =
            this.communitiesStateService.getCurrentCommunity();

        this.recentCommunities$ = this.store.select(selectRecentCommunities);

        this.showDashboardLink$ = this.store.select(selectShowDashboardLink);

        this.sectionSelected$ = combineLatest([
            this.store.select(AppSelectors.selectRouteState),
            this.showDashboardLink$,
        ]).pipe(
            filterMap(([route, showDashboardLink]) =>
                route != null ? { route, showDashboardLink } : undefined,
            ),
            map(({ route, showDashboardLink }) => ({
                sectionId: getSelectedSidebarSection(route, showDashboardLink),
                sectionAdminId: routeToSidebarSectionAdmin(route),
                route,
            })),
            shareReplay(),
            takeUntil(this.destroy$),
        );

        // show / hide insights section
        this.appState.communitiesState.insightsCommunities$
            .pipe(takeUntil(this.destroy$))
            .subscribe((communities) => {
                this.otherSidebarSections.update((sections) =>
                    sections.map((section) => {
                        if (section.id === 'insights') {
                            return {
                                ...section,
                                hidden: communities.length === 0,
                            };
                        } else {
                            return section;
                        }
                    }),
                );
            });

        this.userCapabilities$
            .pipe(takeUntil(this.destroy$))
            .subscribe((userCapabilities) => {
                // show / hide admin section - people section
                this.otherSidebarSections.update((sections) =>
                    sections.map((section) => {
                        switch (section.id) {
                            case 'admin-v2':
                                return {
                                    ...section,
                                    hidden: !userCapabilities.accessAdminArea,
                                };
                            case 'people':
                                return {
                                    ...section,
                                    hidden: !this.currentUser?.userSettings
                                        ?.peopleSectionEnabled,
                                };
                            case 'digital_workplace_admin':
                                return {
                                    ...section,
                                    hidden: !userCapabilities.manageDigitalWorkplace,
                                };
                            default:
                                return section;
                        }
                    }),
                );
            });

        this.showAdvancedCommunityResearch$ = this.store
            .select(selectAdvancedCommunityResearchOpened)
            .pipe(shareReplay());

        this.showAdminV2Sidebar$ = this.store
            .select(selectAdminV2TabOpened)
            .pipe(shareReplay());

        //pezza temporanea per mostrare/nascondere la sidebar della admin
        this.sectionSelected$
            .pipe(
                withLatestFrom(this.showAdminV2Sidebar$),
                filter(
                    ([section, isShowing]) =>
                        (isShowing && section.sectionId != 'admin-v2') ||
                        (!isShowing && section.sectionId === 'admin-v2'),
                ),
                takeUntil(this.destroy$),
            )
            .subscribe(() => this.toggleAdminSidebar());

        //pezza temporanea per mostrare la mostrare/nascondere la sidebar operativa
        this.sectionSelected$
            .pipe(
                withLatestFrom(this.showAdvancedCommunityResearch$),
                concatMap(([section, isShowing]) =>
                    this.advancedCommunitySidebar$.pipe(
                        map((canShow) => ({ section, isShowing, canShow })),
                    ),
                ),
                filter(
                    ({ section, isShowing, canShow }) =>
                        ((isShowing && section.sectionId != 'community') ||
                            (!isShowing &&
                                section.sectionId === 'community')) &&
                        canShow,
                ),
                takeUntil(this.destroy$),
            )
            .subscribe(() => this.toggleAdvancedCommunityResearch());
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    onCollapsedWs(id: number): void {
        this.workspacesUiService.toggleWorkspaceOpen(id);
    }

    toggleAdvancedSidebar(
        sectionId: SidebarSectionId,
        showSpecificSidebar: boolean,
    ): void {
        if (sectionId === 'community') {
            this.toggleAdvancedCommunityResearchAndSwitchFocus(
                showSpecificSidebar,
            );
        } else if (sectionId === 'admin-v2') {
            this.toggleAdvancedAdminSidebarAndSwitchFocus(showSpecificSidebar);
        }
    }

    toggleAdvancedCommunityResearchAndSwitchFocus(
        showAdvancedCommunityResearch: boolean,
    ): void {
        this.toggleAdvancedCommunityResearch();
        requestAnimationFrame(() => {
            document
                .getElementById(
                    showAdvancedCommunityResearch
                        ? 'community'
                        : 'advancedCommunityResearch',
                )
                ?.focus();
        });
    }

    toggleAdvancedAdminSidebarAndSwitchFocus(showAdminSidebar: boolean): void {
        this.toggleAdminSidebar();
        requestAnimationFrame(() => {
            document
                .getElementById(
                    showAdminSidebar ? 'admin-v2' : 'adminV2Sidebar',
                )
                ?.focus();
        });
    }

    handleCreateClick(
        sectionSelected: SectionSelected,
        selectedCommunity: ICommunity | null,
    ): void {
        if (sectionSelected.sectionId === 'admin-v2') {
            if (sectionSelected.sectionAdminId === 'workflow_template') {
                this.router.navigate([adminV2WorkflowCreate]);
            } else {
                this.adminV2SharedStore.dispatch(
                    openCreationDialog({ isOpen: true }),
                );
            }
        } else {
            if (selectedCommunity) {
                this.router.navigate([postCreate, selectedCommunity.id]);
            } else {
                this.sidebarService
                    .openCommunitySelectionForCreateDialog()
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((selected) => {
                        this.router.navigate([postCreate, selected.id]);
                    });
            }
        }
    }

    navigateToCommunity(community: Pick<ICommunity, 'id'>): void {
        this.router.navigate(['/', dashboardCommunity, community.id]);
    }

    disableCreateNewButton(data: {
        sectionSelected: SectionSelected;
        operativityCommunitiesLength: number | undefined;
        selectedCommunity: ICommunity | null;
        userCapabilities: UserCapabilities;
    }): boolean {
        const homeManage =
            (['home', 'manage'].includes(data.sectionSelected.sectionId) ||
                (data.sectionSelected.sectionId === 'community' &&
                    !data.selectedCommunity)) &&
            !data.operativityCommunitiesLength;

        const canCreatePostInCommunity =
            data.sectionSelected.sectionId === 'community' &&
            data.selectedCommunity &&
            !data.selectedCommunity.capabilities?.createPost;

        const canCreateMemberInAdminV2 =
            (data.sectionSelected.sectionAdminId === 'user' ||
                data.sectionSelected.sectionAdminId === 'group') &&
            !data.userCapabilities.createUsers;

        const canCreateWorkspacesInAdminV2 =
            data.sectionSelected.sectionAdminId === 'workspace' &&
            !data.userCapabilities.createWorkspaces;

        const canCreateCommunitiesInAdminV2 =
            data.sectionSelected.sectionAdminId === 'community' &&
            !data.userCapabilities.createCommunities;

        const canCreateWorkflowTemplatesInAdminV2 =
            data.sectionSelected.sectionAdminId === 'workflow_template' &&
            !data.userCapabilities.manageWorkflowTemplates;

        const canCreateCatalogsInAdminV2 =
            data.sectionSelected.sectionAdminId === 'catalog' &&
            !data.userCapabilities.manageCatalogs;

        return (
            homeManage ||
            canCreatePostInCommunity ||
            CreateButtonDisabledSections.includes(
                data.sectionSelected.sectionId,
            ) ||
            (data.sectionSelected.sectionId === 'admin-v2' &&
                data.sectionSelected.sectionAdminId !== null &&
                (canCreateMemberInAdminV2 ||
                    canCreateWorkspacesInAdminV2 ||
                    canCreateCommunitiesInAdminV2 ||
                    canCreateWorkflowTemplatesInAdminV2 ||
                    canCreateCatalogsInAdminV2 ||
                    CreateButtonDisabledAdminSections.includes(
                        data.sectionSelected.sectionAdminId,
                    )))
        );
    }

    openAIPanel(): void {
        this.store.dispatch(AIActions.openGuide());
    }

    private toggleAdvancedCommunityResearch(): void {
        this.store.dispatch(CommunityActions.toggleAdvancedCommunityResearch());
        this.isAnimatingAdvancedTab = true;
    }

    private toggleAdminSidebar(): void {
        this.store.dispatch(CommunityActions.toggleAdminV2Tab());
        this.isAnimatingAdminV2Tab = true;
    }
}
