<ng-container
    *ngIf="{
        showMyCommunities: showMyCommunities$ | async,
        pinnedCommunities: (pinnedCommunities$ | async) ?? []
    } as $"
>
    <ng-container *ngIf="workspaceList">
        <div *ngIf="showDashboardLink" [ngClass]="{ 'px-12': indentation }">
            <a
                *ngIf="$.showMyCommunities"
                class="text-text-mid-contrast hover:text-text ml-8 flex h-40 items-center"
                routerLink="/dashboard"
                routerLinkActive="text-text-primary hover:text-text-primary"
                ><interacta-icon
                    class="mr-8 size-24"
                    icon="people"
                ></interacta-icon>
                <span
                    class="typo-xs-bold text-left transition-opacity duration-500"
                    [ngClass]="{
                        'opacity-0': !expanded,
                        'opacity-100': expanded
                    }"
                    >{{ 'SIDEBAR.MY_COMMUNITIES' | translate }}</span
                >
            </a>
            <interacta-separator
                *ngIf="$.showMyCommunities && workspaceList.length"
                thickness="soft"
                [ngClass]="{ 'block w-40': !expanded }"
            >
            </interacta-separator>
        </div>
        @if (pinEnabled && $.pinnedCommunities.length) {
            <section
                class="flex flex-col pt-8"
                @pinned
                [ngClass]="{ 'px-12': indentation }"
            >
                <div
                    class="text-text-mid-contrast hover:text-text relative mb-4 h-24"
                >
                    <interacta-icon
                        class="absolute size-24 transition-opacity duration-500"
                        [icon]="'pin'"
                        [ngClass]="{
                            'opacity-0': expanded,
                            'opacity-100': !expanded,
                            'ml-8': indentation
                        }"
                    />
                    <span
                        class="typo-xs-bold absolute my-4 block transition-opacity duration-500"
                        [ngClass]="{
                            'opacity-0': !expanded,
                            'opacity-100': expanded,
                            'px-12': indentation
                        }"
                        >{{
                            'SIDEBAR.PINNED_COMMUNITIES' | translate | uppercase
                        }}</span
                    >
                </div>
                @for (community of $.pinnedCommunities; track community.id) {
                    <interacta-sidebar-community
                        *ngIf="
                            redirectTo === 'community' &&
                            community.visibleInOrganizationTree
                        "
                        @pinned
                        [community]="community"
                        [expanded]="expanded"
                        [ngClass]="{ 'mb-4': $last }"
                        [pinVisible]="pinEnabled"
                        [pinned]="
                            {
                                community,
                                pinnedCommunities: $.pinnedCommunities
                            } | apply: isPinned
                        "
                        [selected]="
                            selectedCommunity !== null &&
                            community.id === selectedCommunity.id
                        "
                        (communityPinned)="
                            pinCommunity({ community, value: $event })
                        "
                        (communitySelected)="
                            selectedCommunityChanged.emit(community)
                        "
                    ></interacta-sidebar-community>
                }
                <interacta-separator
                    thickness="soft"
                    [ngClass]="{
                        'block w-40': !expanded
                    }"
                >
                </interacta-separator>
            </section>
        }
        @for (workspace of workspaceList; track workspace.id) {
            <div
                *ngIf="uiStates != null && uiStates[workspace.id] as uiState"
                [ngClass]="{ 'px-12': indentation }"
            >
                <div class="py-4">
                    <button
                        class="w-full text-left"
                        type="button"
                        (click)="collapsedWs.emit(workspace.id)"
                    >
                        <interacta-sidebar-workspace
                            [expanded]="expanded"
                            [isCollapsed]="uiState.isAccordionOpen"
                            [selected]="
                                selectedCommunity !== null &&
                                workspace.id === selectedCommunity.workspaceId
                            "
                            [workspace]="workspace"
                        ></interacta-sidebar-workspace>
                    </button>

                    <div *ngIf="uiState.isAccordionOpen">
                        @for (
                            community of workspace.communities;
                            track community.id
                        ) {
                            <interacta-sidebar-community
                                *ngIf="
                                    (redirectTo === 'community' &&
                                        community.visibleInOrganizationTree) ||
                                    (redirectTo === 'create' &&
                                        community.capabilities?.createPost &&
                                        community.visibleInOrganizationTree)
                                "
                                [community]="community"
                                [expanded]="expanded"
                                [pinVisible]="pinEnabled"
                                [pinned]="
                                    {
                                        community,
                                        pinnedCommunities: $.pinnedCommunities
                                    } | apply: isPinned
                                "
                                [selected]="
                                    selectedCommunity !== null &&
                                    community.id === selectedCommunity.id &&
                                    workspace.id ===
                                        selectedCommunity.workspaceId
                                "
                                (communityPinned)="
                                    pinCommunity({ community, value: $event })
                                "
                                (communitySelected)="
                                    selectedCommunityChanged.emit(community)
                                "
                            ></interacta-sidebar-community>
                        }
                    </div>
                </div>
                <interacta-separator
                    *ngIf="!$last"
                    thickness="soft"
                    [ngClass]="{ 'block w-40': !expanded }"
                >
                </interacta-separator>
            </div>
        }
    </ng-container>
</ng-container>
